import * as React from 'react'
import styled from 'styled-components'

import { COLORS } from '../../constants'

import Container from '../Container/Container'

const StyledSectionTitle = styled.h2`
  margin-bottom: 4.8rem;
  font-size: 2.4rem;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
`

interface SectionTitleProps {
  children?: React.ReactNode
}

export const SectionTitle = ({ children }: SectionTitleProps) => (
  <StyledSectionTitle>{children}</StyledSectionTitle>
)

const StyledSection = styled.section`
  padding: 4.8rem 0;
`

interface SectionProps {
  children?: React.ReactNode
  className?: string
  id?: string
}

export const Section = ({ children, className, id }: SectionProps) => (
  <StyledSection className={className} id={id}>
    <Container>
      {children}
    </Container>
  </StyledSection>
)

const StyledAltSection = styled(StyledSection)`
  background: ${COLORS.greyLight};
`

export const AltSection = ({ children, id }: SectionProps) => (
  <StyledAltSection id={id}>
    <Container>
      {children}
    </Container>
  </StyledAltSection>
)