import * as React from 'react'
import Helmet from 'react-helmet'

import Footer from '../Footer/Footer'
import Reset from '../Reset/Reset'
import Map from '../Map/Map'

import { CategoryShape } from '../Projects/Projects'
import { ProjectShape } from '../Project/Project'

const meta = [
  { name: 'description', content: '' },
  { name: 'keywords', content: '' },
]

interface LayoutProps {
  categories?: CategoryShape[]
  projects?: ProjectShape[]
  project?: ProjectShape
  quotes?: []
  translations: Object
  teamMembers?: []
  sections?: {}
  values?: []
  currentLang: string,
  children: React.ReactNode
}

const Page = ({ children, currentLang, project, translations }: LayoutProps) => {
  const title = project ? `${project.name} - ${translations['page.title']}` : translations['page.title'];

  return (
    <React.Fragment>
      <Helmet title={title} meta={meta}>
        <html lang={currentLang} />
        <link href="https://fonts.googleapis.com/css?family=Lato:300,400,700" rel="stylesheet"></link>
      </Helmet>

      <Reset />

      {children}


      <Map translations={translations} />
      <Footer translations={translations} />
    </React.Fragment>
  )
}

export default Page