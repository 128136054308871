import * as React from 'react'
import styled from 'styled-components'

interface ContainerProps {
  children: React.ReactNode
  className?: string
  tag?: string
}

const StyledContainer = styled.div`
  max-width: 113.2rem;
  padding: 0 1.6rem;
  margin: 0 auto;
`

const Container = ({ children, className, tag}: ContainerProps) => (
  <StyledContainer as={tag} className={className}>{children}</StyledContainer>
)

export default Container