import * as React from 'react'
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps'
import InfoBox from 'react-google-maps/lib/components/addons/InfoBox';
import mapStyles from './map-styles.json'
import styled from 'styled-components'

import { COLORS } from '../../constants'

const StyledInfoBox = styled.div`
  width: 30rem;
  padding: 2.5rem 4rem;
  background: ${COLORS.white};

  p {
    margin-bottom: 1.2rem;
    line-height: 2.2rem;

    &:last-child {
      margin: 0;
    }
  }

  a {
    color: ${COLORS.grey};
  }
`;

const InfoBoxTitle = styled.h1`
  margin-bottom: 3rem;
  font-size: 2rem;
  font-weight: 600;
  color: ${COLORS.green};
`;

const GMap = withScriptjs(withGoogleMap(({ translations }) => (
  <GoogleMap
    defaultZoom={16}
    defaultCenter={{ lat: 50.045103, lng: 19.956399 }}
    defaultOptions={{ styles: mapStyles }}
  >
    <InfoBox defaultPosition={new google.maps.LatLng({ lat: 50.046713, lng: 19.957804 })} defaultOptions={{ closeBoxURL: '' }}>
      <React.Fragment>
        <Marker position={{ lat: 50.045103, lng: 19.956399 }} />
        <StyledInfoBox>
          <InfoBoxTitle>{translations['contact.title']}</InfoBoxTitle>
          <p>
            {translations['contact.address']}<br />
            {translations['contact.zipcode']} {translations['contact.city']}<br />
            {translations['contact.country']}
          </p>

          {translations['contact.phone'] && <p>{translations['contact.phone']}</p>}

          {translations['contact.email'] && <p><a href={`mailto:${translations['contact.email']}`}>{translations['contact.email']}</a></p>}
        </StyledInfoBox>
      </React.Fragment>
    </InfoBox>
  </GoogleMap>
)))

const Map = ({ translations }) => (
  <div id="contact">
    <GMap
      googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBoLj4_y4Wv3fgVXnbiotcy1P3AuwS5Bpw"
      loadingElement={<div style={{ height: `100%` }} />}
      containerElement={<div style={{ height: `43.5rem` }} />}
      mapElement={<div style={{ height: `100%` }} />}
      translations={translations}
    />
  </div>
)

export default Map