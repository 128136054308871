import * as React from 'react'
import Link from 'gatsby-link'
import styled from 'styled-components'

import { SecondaryButton } from '../Button/Button'
import ProjectItem from '../ProjectItem/ProjectItem'

const StyledProjectsList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1rem 0.8rem;

  @media(min-width: 1025px) {
    margin: 0 -2rem 0.8rem;
  }
`

const Pagination = styled.div`
  text-align: center;
`

interface ProjectsListProps {
  projects: Object[]
  showProjectsLink?: boolean
}

const ProjectsList = ({ projects, showProjectsLink }: ProjectsListProps) => (
  <React.Fragment>
    <StyledProjectsList>
      {projects.map((project) => <ProjectItem project={project} key={project.url} />)}
    </StyledProjectsList>


    {showProjectsLink && <Pagination>
      <SecondaryButton as={Link} to='/projects'>Zobacz więcej</SecondaryButton>
    </Pagination>}
  </React.Fragment>
);

export default ProjectsList