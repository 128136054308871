import * as React from 'react'
import styled from 'styled-components'

const StyledContent = styled.div`
  p, ul, ol {
    margin-bottom: 1.4rem;
    line-height: 2rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  strong {
    font-weight: 700;
  }

  em {
    font-style: italic;
  }

  h1, h2, h3, h4, h5, h6 {
    margin-bottom: 1.4rem;
    font-weight: 700;
  }
`

interface ContentProps {
  className?: string,
  children?: React.ReactNode,
  safeContent?: string
}

const Content = ({ children, className, safeContent }: ContentProps) => (
  safeContent
    ? <StyledContent className={className} dangerouslySetInnerHTML={{__html: safeContent}} />
    : <StyledContent className={className}>{children}</StyledContent>
)


export default Content