import * as React from 'react'
import styled from 'styled-components'

import Image from '../Image/Image'

import { COLORS } from '../../constants'

const StyledProject = styled.li`
  width: 100%;
  margin: 0 1rem 4rem;

  @media(min-width: 641px) {
    width: calc(50% - 2rem);
  }

  @media(min-width: 1024px) {
    width: calc((100% / 3) - 4rem);
    margin: 0 2rem 4rem;
  }
`

const ProjectLink = styled.a`
  display: block;
  height: 100%;
  overflow: hidden;
  border-radius: 0.6rem;
  background: ${COLORS.white};
  color: ${COLORS.grey};

  &:hover {
    text-decoration: none;
    cursor: pointer;
  }
`

const Meta = styled.div`
  padding: 2rem;
`

const Address = styled.div`
  margin-bottom: 0.5rem;
  font-size: 1.2rem;
  text-transform: uppercase;
  color: ${COLORS.greyDark};
`

const Name = styled.h3`
  font-size: 2rem;
  font-weight: 300;
`

const Details = styled.ul`
  display: flex;
  border-top: solid 1px ${COLORS.greyLight};
`

const Detail = styled.li`
  width: 50%;
  padding: 1.6rem 2rem;
  border-left: solid 1px ${COLORS.greyLight};

  &:forst-child {
    border: none;
  }
`

const DetailName = styled.div`
  margin-bottom: 1rem;
  font-size: 1.2rem;
  text-transform: uppercase;
  color: ${COLORS.greyDark};
`

const DetailValue = styled.div`
  font-size: 1.8rem;
  font-weight: 300;
  color: ${COLORS.green};
`

const getImageSources = (image) => [
  { mediaQuery: '(min-width: 1025px) and (min-resolution: 192dpi)', srcSet: image.size340x2 },
  { mediaQuery: '(min-width: 1025px)', srcSet: image.size340 },
  { mediaQuery: '(min-width: 769px) and (min-resolution: 192dpi)', srcSet: image.size486x2 },
  { mediaQuery: '(min-width: 769px)', srcSet: image.size486 },
  { mediaQuery: '(min-width: 768px) and (min-resolution: 192dpi)', srcSet: image.size358x2 },
  { mediaQuery: '(min-width: 768px)', srcSet: image.size358 },
  { mediaQuery: '(min-width: 641px) and (min-resolution: 192dpi)', srcSet: image.size295x2 },
  { mediaQuery: '(min-width: 641px)', srcSet: image.size295 },
  { mediaQuery: '(min-width: 321px) and (min-resolution: 192dpi)', srcSet: image.size343x2 },
  { mediaQuery: '(min-width: 321px)', srcSet: image.size343 },
  { mediaQuery: '(min-resolution: 192dpi)', srcSet: image.size288x2 },
]

const ProjectItem = ({ project }) => (
  <StyledProject>
    <ProjectLink href={`/project/${project.url}`}>
      {project.mainImage && project.mainImage &&
        <Image
          sources={getImageSources(project.mainImage)}
          src={project.mainImage.size288}
          alt={project.name}
        />
      }

      <Meta>
        <Address>{project.location}</Address>
        <Name>{project.name}</Name>
      </Meta>

      <Details>
        {project.surface &&
          <Detail>
            <DetailName>Powierzchnia</DetailName>
            <DetailValue>{project.surface}</DetailValue>
          </Detail>
        }

        {project.year &&
          <Detail>
            <DetailName>Rok</DetailName>
            <DetailValue>{project.year}</DetailValue>
          </Detail>
        }
      </Details>
    </ProjectLink>
  </StyledProject>
)

export default ProjectItem