import * as React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

import { COLORS } from '../../constants'

import Content from '../Content/Content'
import { AltSection, Section, SectionTitle } from '../Section/Section'
import ProjectsList from '../ProjectsList/ProjectsList'
import Project, { ProjectShape } from '../Project/Project'

const SectionDescription = styled(Content)`
  max-width: 76.8rem;
  padding: 0 1.6rem;
  margin: 0 auto 3.2rem;
  text-align: center;
`

const ProjectsCategoriesList = styled.ul`
  margin-bottom: 4.8rem;
  text-align: center;
`

const ProjectsCategoriesLink = styled.a`
  font-weight: 700;
  color: ${COLORS.greyDark};
  text-transform: uppercase;

  &:hover {
    color: ${COLORS.green};
    text-decoration: none;
  }
`

const ProjectsCategoriesItem = styled.li`
  display: block;
  margin-bottom: 2rem;

  ${({ isActive }) => (
    isActive && `& > ${ProjectsCategoriesLink} {
      color: ${COLORS.green};
    }`
  )}

  @media(min-width: 768px) {
    display: inline-flex;
    padding-right: 2.5rem;
    margin-right: 2.3rem;
    margin-bottom: 0;
    align-items: center;
    position: relative;

    &::after {
      content: '';
      width: 0.2rem;
      height: 0.9rem;
      position: absolute;
      right: 0;
      background: ${COLORS.greyDark};
    }

    &:last-child {
      padding: 0;
      margin: 0;

      &::after {
        display: none;
      }
    }
  }
`
export interface CategoryShape {
  url: string,
  name: string
}

interface ProjectsSectionProps {
  categories: CategoryShape[]
  projects?: ProjectShape[]
  project?: ProjectShape
  translations: Object
  section: Object
  currentCategory: string
  showProjectsLink?: boolean
}

const Projects = ({ currentCategory, categories, project, projects, section, translations, showProjectsLink }: ProjectsSectionProps) => {
  const categoryAll = {
    name: translations['projects.category_all.name'],
    url: ''
  }

  const projectsCategories = [categoryAll, ...categories]

  if (!currentCategory && project) {
    currentCategory = project.category
  }

  if (!currentCategory && !project) {
    currentCategory = categoryAll
  }

  const SectionTag = projects ? AltSection : Section;

  return (
    <SectionTag id="projects">
      {section.name && <SectionTitle>{section.name}</SectionTitle>}

      {section.description && <SectionDescription safeContent={section.description} />}

      <ProjectsCategoriesList>
        {projectsCategories.map(({ name, url }) => (
          <ProjectsCategoriesItem key={name} isActive={currentCategory && currentCategory.url && url === currentCategory.url}>
            <ProjectsCategoriesLink href={
              url ? `/projects/${url}` : '/projects'
            }> {name}</ProjectsCategoriesLink>
          </ProjectsCategoriesItem>
        ))}
      </ProjectsCategoriesList>

      {!projects && project && <Project project={project} />}
      {projects && !project && <ProjectsList projects={projects} showProjectsLink={showProjectsLink} />}
    </SectionTag>
  )
}

export default Projects