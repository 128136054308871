import * as React from 'react'
import styled from 'styled-components'

import { COLORS } from '../../constants'

const SocialMediaList = styled.ul`
  padding: 4.8rem 0;
  background: ${COLORS.green};
  text-align: center;
`

const SocialMediaItem = styled.li`
  display: inline-block;
  margin-right: 2.4rem;

  &:last-child {
    margin: 0;
  }
`

const SocialMediaLink = styled.a`
  display: inline-flex;
  width: 5.5rem;
  height: 5.5rem;
  align-items: center;
  justify-content: center;
  background: ${COLORS.white};
  border-radius: 50%;

  svg {
    fill: ${COLORS.green};
  }
`;

const StyledFooter = styled.p`
  padding: 1.6rem 0;
  font-size: 1.4rem;
  text-align: center;
  color: ${COLORS.white};
  background: ${COLORS.greenDark};
`

const Footer = ({ translations }) => (
  <React.Fragment>
    <SocialMediaList>
      <SocialMediaItem>
        <SocialMediaLink href={translations['social.facebook']} title="Facebook">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z"/></svg>
        </SocialMediaLink>
      </SocialMediaItem>

      <SocialMediaItem>
        <SocialMediaLink href={translations['social.instagram']} title="Instagram">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
          <path d="M11.984 16.815a4.71 4.71 0 0 0 4.706-4.707 4.717 4.717 0 0 0-4.705-4.707c-.692 0-1.349.151-1.941.421a4.752 4.752 0 0 0-1.158.749 4.695 4.695 0 0 0-1.608 3.538 4.712 4.712 0 0 0 4.706 4.706zM12 8.631a3.479 3.479 0 1 1 0 6.957 3.479 3.479 0 0 1 0-6.957zM17.223 9H24v10.278A4.722 4.722 0 0 1 19.278 24H4.785C2.177 24 0 21.886 0 19.278V9h6.747a6.048 6.048 0 0 0-.872 3.109 6.109 6.109 0 1 0 12.218 0A6.047 6.047 0 0 0 17.223 9zm2.055-9H7v5H6V0H5v5H4V.077a4.79 4.79 0 0 0-1 .27V5H2V.898c-1.202.857-2 2.246-2 3.824V8h7.473a6.087 6.087 0 0 1 9.022 0H24V4.722A4.722 4.722 0 0 0 19.278 0zM22 5.265a.74.74 0 0 1-.745.735h-2.511A.74.74 0 0 1 18 5.265v-2.53A.74.74 0 0 1 18.744 2h2.511a.74.74 0 0 1 .745.735v2.53z"/>
        </svg>
        </SocialMediaLink>
      </SocialMediaItem>

      <SocialMediaItem>
        <SocialMediaLink href={translations['social.behance']} title="Behance+">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
          <path d="M22 7h-7V5h7v2zm1.726 10c-.442 1.297-2.029 3-5.101 3-3.074 0-5.564-1.729-5.564-5.675 0-3.91 2.325-5.92 5.466-5.92 3.082 0 4.964 1.782 5.375 4.426.078.506.109 1.188.095 2.14H15.97c.13 3.211 3.483 3.312 4.588 2.029h3.168zm-7.686-4h4.965c-.105-1.547-1.136-2.219-2.477-2.219-1.466 0-2.277.768-2.488 2.219zm-9.574 6.988H0V5.021h6.953c5.476.081 5.58 5.444 2.72 6.906 3.461 1.26 3.577 8.061-3.207 8.061zM3 11h3.584c2.508 0 2.906-3-.312-3H3v3zm3.391 3H3v3.016h3.341c3.055 0 2.868-3.016.05-3.016z"/>
        </svg>
        </SocialMediaLink>
      </SocialMediaItem>

      <SocialMediaItem>
        <SocialMediaLink href={translations['social.linkedin']} title="Linkedin">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z"/></svg>
        </SocialMediaLink>
      </SocialMediaItem>
    </SocialMediaList>

    <StyledFooter>2018 All Rights Reserved.</StyledFooter>
  </React.Fragment>
)

export default Footer