import * as React from 'react'
import Link from 'gatsby-link'
import styled from 'styled-components'

import { COLORS } from '../../constants'
import LogoSrc from './logo.svg'

import Container from '../Container/Container'

const Logo = styled.img`
  width: 7.2rem;
  height: 7.2rem;
`

const NavContainer = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 1.6rem;
  padding-bottom: 1.6rem;
`

const NavList = styled.ul``

const NavItem = styled.li`
  display: inline-flex;
  padding: 0 3rem 0 2.8rem;
  position: relative;
  align-items: center;

  &::after {
    content: '';
    display: inline-block;
    width: 0.2rem;
    height: 0.9rem;
    position: absolute;
    right: 0;
    background: ${COLORS.white};
  }

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;

    &::after {
      display: none;
    }
  }
`

const NavLink = styled.a`
  font-size: 1.2rem;
  font-weight: 700;
  text-transform: uppercase;
  color: ${COLORS.white};
`

const Nav = ({ className, links }) => {

  const handleScroll = (e, elementId) => {
    if (elementId && elementId.charAt(0) === '#') {
      e.preventDefault()


      setTimeout(() => {
        const element = document.getElementById(elementId.replace('#', ''))
        element && element.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'start'
        })
      })
    }
  }

  return (
    <NavContainer className={className}>
      <Link to="/" title="Tekturowi Bohaterowie">
        <Logo src={LogoSrc} alt="Tekturowi Bohaterowie" />
      </Link>

      <NavList>
        {links.map(({ url, label }) => (
          <NavItem key={url}>
            <NavLink href={url} onClick={(e) => handleScroll(e, url)}>{label}</NavLink>
          </NavItem>
        ))}
      </NavList>
    </NavContainer>
  )
}

export default Nav