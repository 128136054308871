import * as React from 'react'
import styled from 'styled-components'

import { COLORS } from '../../constants'

export const PrimaryButton = styled.button`
  padding: 1.6rem 3.6rem;
  border: solid 0.2rem ${COLORS.white};
  border-radius: 0.6rem;
  font-size: 1.6rem;
  color: ${COLORS.white};
  background: transparent;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
`
export const SecondaryButton = styled(PrimaryButton)`
  border-color: ${COLORS.greyLighter};
  color: ${COLORS.grey};
`