import * as React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

import Content from '../Content/Content'
import Image from '../Image/Image'

import { COLORS } from '../../constants'

const StyledProject = styled.div`
  @media(min-width: 1024px) {
    display: flex;
  }
`

const ProjectImages = styled.div`
  @media(min-width: 1024px) {
    padding-right: 3.5rem;
    flex-grow: 1;
    flex-shrink: 1;
  }
`

const ProjectImageWrapper = styled.div`
  margin-bottom: 2rem;
  position: relative;

  @media(min-width: 1025px) {
    margin-bottom: 4rem;
  }
`

const ProjectImage = styled(Image)`
  display: ${({ visible }) => visible ? 'block' : 'none'};
`

const ProjectImageSwitch = styled.button`
  display: flex;
  width: 6rem;
  height: 100%;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  background: transparent;
  cursor: pointer;
`
const ProjectImageSwitchPrev = styled(ProjectImageSwitch)`
  left: 0;
`

const ProjectImageSwitchNext = styled(ProjectImageSwitch)`
  right: 0;
`

const ProjectImagesList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -0.6rem 2rem;

  @media(min-width: 375px) {
    margin: 0 -1rem 2rem;
  }

  @media(min-width: 414px) {
    margin: 0 -1rem 2rem;
  }

  @media(min-width: 1025px) {
    margin: 0 -2rem;
  }
`

const ProjectImagesItem = styled.li`
  width: calc(50% - 1rem);
  margin: 0 0.5rem 1rem;
  cursor: pointer;

  @media(min-width: 640px) {
    width: calc((100% / 3) - 2rem);
    margin: 0 1rem 2rem;
  }

  @media(min-width: 1025px) {
    width: calc((100% / 4) - 4rem);
    margin: 0 2rem 4rem;
  }
`

const ProjectDetails = styled.div`
  flex-basis: 30rem;
  flex-grow: 0;
  flex-shrink: 0;
`

const ProjectName = styled.h2`
  margin-bottom: 2.6rem;
  font-size: 2.4rem;
  font-weight: 700;
`

const ProjectDescription = styled(Content)`
  margin-bottom: 2.6rem;
`

const ProjectProps = styled.ul``

const ProjectProp = styled.li`
  display: block;
  padding-bottom: 1.3rem;
  margin-bottom: 2.6rem;
  border-bottom: solid 1px ${COLORS.greyLighter};

  &:last-child {
    margin-bottom: 0;
  }
`

const ProjectPropName = styled.h3`
  margin-bottom: 0.8rem;
  font-size: 1.4rem;
  color: ${COLORS.greyDark};
  text-transform: uppercase;
`

const ProjectPropValue = styled.p`
  font-size: 1.4rem;
  line-height: 1.8rem;

  p {
    margin: 0;
  }
`

export interface ProjectShape {
  name: string
  description: string
  category: Object
  location: string
  surface: number
  services: string
  year: number
  images: []
}

interface ProjectProps {
  project: ProjectShape
}

interface PropjectState {
  currentImageIndex: number
}

const getImageSources = (image) => [
  { mediaQuery: '(min-width: 1025px) and (min-resolution: 192dpi)', srcSet: image.size162x2 },
  { mediaQuery: '(min-width: 1025px)', srcSet: image.size162 },
  { mediaQuery: '(min-width: 769px) and (min-resolution: 192dpi)', srcSet: image.size206x2 },
  { mediaQuery: '(min-width: 769px)', srcSet: image.size206 },
  { mediaQuery: '(min-width: 768px) and (min-resolution: 192dpi)', srcSet: image.size232x2 },
  { mediaQuery: '(min-width: 768px)', srcSet: image.size232 },
  { mediaQuery: '(min-width: 321px) and (min-resolution: 192dpi)', srcSet: image.size190x2 },
  { mediaQuery: '(min-width: 321px)', srcSet: image.size190 },
  { mediaQuery: '(min-resolution: 192dpi)', srcSet: image.size140x2 },
]

const getMainImageSources = (image) => [
  { mediaQuery: '(min-width: 1025px) and (min-resolution: 192dpi)', srcSet: image.size765x2 },
  { mediaQuery: '(min-width: 1025px)', srcSet: image.size765 },
  { mediaQuery: '(min-width: 769px) and (min-resolution: 192dpi)', srcSet: image.size658x2 },
  { mediaQuery: '(min-width: 769px)', srcSet: image.size658 },
  { mediaQuery: '(min-width: 768px) and (min-resolution: 192dpi)', srcSet: image.size736x2 },
  { mediaQuery: '(min-width: 768px)', srcSet: image.size736 },
  { mediaQuery: '(min-width: 321px) and (min-resolution: 192dpi)', srcSet: image.size609x2 },
  { mediaQuery: '(min-width: 321px)', srcSet: image.size609 },
  { mediaQuery: '(min-resolution: 192dpi)', srcSet: image.size288x2 },
]

export default class Project extends React.Component<ProjectProps, PropjectState>{
  state = {
    currentImageIndex: 0
  }

  setCurrentImageIndex = (imageIndex: number) => {
    this.setState({ currentImageIndex: imageIndex })
  }

  setNextImage = () => {
    const imagesCount = this.props.project.images.length;
    const { currentImageIndex } = this.state;
    const nextIndex = currentImageIndex === imagesCount - 1 ? 0 : currentImageIndex + 1;

    this.setState({ currentImageIndex: nextIndex });
  }

  setPrevImage = () => {
    const imagesCount = this.props.project.images.length;
    const { currentImageIndex } = this.state;
    const prevIndex = currentImageIndex === 0 ? imagesCount - 1 : currentImageIndex - 1;

    this.setState({ currentImageIndex: prevIndex });
  }

  render() {
    const { project } = this.props
    const { currentImageIndex } = this.state

    return (
      <StyledProject>
        <ProjectImages>
          <ProjectImageWrapper>
            <ProjectImageSwitchPrev onClick={this.setPrevImage}>&lt;</ProjectImageSwitchPrev>

            {project.images.map((image, index) => (
              <ProjectImage
                sources={getMainImageSources(image)}
                src={image.size288}
                alt=""
                key={image.size288}
                visible={currentImageIndex === index}
              />
            ))}

            <ProjectImageSwitchNext onClick={this.setNextImage}>&gt;</ProjectImageSwitchNext>
          </ProjectImageWrapper>

          {project.images && project.images.length &&
            <ProjectImagesList>
              {project.images.map((image, index) => (
                <ProjectImagesItem
                  key={image.size140}
                  onClick={() => this.setCurrentImageIndex(index)}
                >
                  <Image sources={getImageSources(image)} src={image.size140} alt="" />
                </ProjectImagesItem>
              ))}
            </ProjectImagesList>
          }
        </ProjectImages>

        <ProjectDetails>
          <ProjectName>{project.name}</ProjectName>
          <ProjectDescription safeContent={project.description} />

          <ProjectProps>
            { project.category && project.category.name &&
              <ProjectProp key="category">
                <ProjectPropName>Kategoria</ProjectPropName>
                <ProjectPropValue>{project.category.name}</ProjectPropValue>
              </ProjectProp>
            }

            {project.location &&
              <ProjectProp key="location">
                <ProjectPropName>Lokalizacja</ProjectPropName>
                <ProjectPropValue>{project.location}</ProjectPropValue>
              </ProjectProp>
            }

            {project.surface &&
              <ProjectProp key="surface">
                <ProjectPropName>Powierzchnia</ProjectPropName>
                <ProjectPropValue>{project.surface}</ProjectPropValue>
              </ProjectProp>
            }

            {project.services &&
              <ProjectProp key="services">
                <ProjectPropName>Zakres usług</ProjectPropName>
                <ProjectPropValue as={Content} safeContent={project.services} />
              </ProjectProp>
            }

            {project.year &&
              <ProjectProp key="year">
                <ProjectPropName>Rok</ProjectPropName>
                <ProjectPropValue>{project.year}</ProjectPropValue>
              </ProjectProp>
            }

            {project.team &&
              <ProjectProp key="team">
                <ProjectPropName>Zespół</ProjectPropName>
                <ProjectPropValue as={Content} safeContent={project.team} />
              </ProjectProp>
            }

          </ProjectProps>
        </ProjectDetails>
      </StyledProject>
    )
  }
}