import * as React from 'react'
import styled from 'styled-components'

const Picture = styled.picture`
  display: block;
  width: 100%;
  max-width: 100%;
`

const Img = styled.img`
  width: 100%;
  max-width: 100%;
`

interface Source {
  mediaQuery: string,
  srcSet: string
}

interface ImageProps {
  sources: Source[]
  src: string
  alt?: string
  className?: string
}

const Image = ({ sources, src, alt, className }: ImageProps) => (
  <Picture className={className}>
    {sources.map(({ mediaQuery, srcSet }) => (
      <source media={mediaQuery} srcSet={srcSet} key={`${mediaQuery}${srcSet}`} />
    ))}

    <Img src={src} alt={alt} />
  </Picture>
)

export default Image