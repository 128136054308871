import * as React from 'react'
import styled from 'styled-components'
import { rgba } from 'polished'

import { COLORS } from '../../constants'

import Nav from '../Nav/Nav'

const links = [
  { url: '/projects', label: 'Projekty'},
  { url: '/#about', label: 'O nas'},
  { url: '/#contact', label: 'Kontakt'},
]

const StyledHeader = styled.header`
  background: ${COLORS.greenDark};
  opacity: 0;
  transition: opacity 0.3s;

  ${({ isVisible }) => isVisible && `opacity: 1;`}

  @media(min-width: 769px) {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;

    &.is-fixed {
      background: ${rgba(COLORS.greenDark, 0.85)};
    }

  }
`

interface Props {
  displayOn?: number
  isFixed?: boolean
}

interface State {
  isFixed: boolean
  isVisible: boolean
}

class PageHeader extends React.Component<Props, State> {

  constructor(props) {
    super(props)

    this.state = { isFixed: false, isVisible: props.displayOn === undefined };
  }

  componentDidMount() {
    const viewPort = typeof matchMedia !== 'undefined' && matchMedia('(min-width: 769px)')

    const handleMq = (mq) => {    
      typeof window !== 'undefined' && window.addEventListener('scroll', this.toggleFixedNav)

      !mq.matches && this.setState({ isFixed: false })
    }

    if (viewPort) {
      viewPort.addListener(handleMq)
      handleMq(viewPort)
    }
  }
  
  toggleFixedNav = () => {
    const { displayOn } = this.props
    const { isFixed } = this.state;

    if (typeof window !== 'undefined') {
      isFixed && window.pageYOffset < 50 && this.setState({ isFixed: false })
      !isFixed && window.pageYOffset > 50 && this.setState({ isFixed: true })

      displayOn !== undefined && window.pageYOffset < displayOn && this.setState({ isVisible: false });
      displayOn !== undefined && window.innerWidth > 768 && window.pageYOffset > displayOn && this.setState({ isVisible: true });
    }
  }

  render() {
    const { isFixed, isVisible } = this.state;
    const fixedNavClassName = isFixed ? 'is-fixed' : ''

    return (
      <StyledHeader className={fixedNavClassName} isVisible={isVisible} as="div">
        <Nav links={links} />
      </StyledHeader>
    )
  }
}

export default PageHeader;